
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useStore } from "vuex";
import "viewerjs/dist/viewer.css";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "motor-insurance-policy-details",
  components: { GoBackButton },
  props: ["publicId"],
  data() {
    return {
      // publicId: this.$route.params.publicId,
      policyDetailsSummary: {},
      avatarUrl: "",
      imageURL: "",
      route: "",
      policyRoute: variables.POLICIES_ROUTE,
      userRole: "",
    };
  },
  setup() {
    const {
      isEmptyArray,
      isObjectEmpty,
      isObjectTrulyEmpty,
    } = ResusableFunctions();

    const downloadImage = (imageURL, publicId) => {
      const link = document.createElement("a");
      link.href = `${imageURL}${publicId}`;
      link.download = publicId;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Policy Details", [
        "Policy",
        "Policy Information",
        "Car Insurance",
      ]);
    });

    return {
      isEmptyArray,
      isObjectEmpty,
      isObjectTrulyEmpty,
      downloadImage,
    };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;
    this.userRole = userType.role;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Policy information route based on user roles
    if (userType.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = variables.POLICIES_ROUTE + "/folio-item/" + this.publicId;
    } else if (userType.role == variables.BROKER_USER_ROLE) {
      // Broker role policy information route
      this.route = variables.BROKER_CLIENT_MOTOR_SCHEDULE_ROUTE + this.publicId;
    } else if (
      userType.role == variables.ADMIN_USER_ROLE ||
      userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role policy information route
      this.route =
        variables.SUPERADMIN_CLIENT_MOTOR_SCHEDULE_ROUTE + this.publicId;
    } else {
      this.route = `${variables.UNDERWRITERS_FOLIO_ROUTE}/${this.publicId}`;
    }

    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;

    // Get the details of the vehicle in schdule list
    this.getVehicleDetails();

    //Set page title
    document.title =
      "Car Insurance Policy Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getVehicleDetails() {
      //Get cart policyDetailsSummary details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policyDetailsSummary = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
    daysDifference(endDate) {
      const startDate = variables.currentDate();
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      return variables.daysDifference(startDateObj, endDateObj);
    },
    downloadCertificate(publicId, registrationNumber) {
      const submitButton1 = document.getElementById(publicId);

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(
            `${variables.POLICIES_ROUTE}/${publicId}/cover-document`
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              const pdfFormat = "application/pdf";

              const mimeType =
                response.data.type == pdfFormat ? pdfFormat : "image/jpeg";
              const mimeTypeExtension =
                response.data.type == pdfFormat ? "pdf" : "jpeg";

              const blob = new Blob([response.data], {
                type: mimeType,
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `${registrationNumber}-certificate.${mimeTypeExtension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
              submitButton1.removeAttribute("data-kt-indicator");
            });
        }
      }
    },
    printPage(el) {
      variables.printPage(el);
    },
  },
});
